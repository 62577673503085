/* main.css */

.header-content {
    font-family: Arial, sans-serif;
    color: #5c0b0b;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    max-width: auto; 
  }
  
  .header {
    background-color: #800020; /* Burgundy background */
    color: #800020;
    text-align: center;
    padding: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: .5rem;
    animation: fadeIn 1.5s ease-out;
  }
  
  .header p {
    margin: 5px 0;
    font-size: 1.2rem;
  }
  
  .main-content {
    padding: 10px;
    max-width: auto; 
    margin: 0 auto;
  }
  
  .about-header{
    max-width: auto; 
    margin: 0 auto;
  }
  .event-details, .rsvp {
    background-color: white;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    max-width: inherit; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .event-details h2, .rsvp h2 {
    font-size: 1.8rem;
    color: #800020;
    margin-top: 0;
    animation: slideIn 1.5s ease-out;
  }
  
  .event-details ul {
    list-style: none;
    padding: 0;
  }
  
  .event-details li {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
  
  .rsvp form {
    display: flex;
    flex-direction: column;
  }
  
  .rsvp input, .rsvp button {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .rsvp button {
    background-color: #800020; /* Burgundy */
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .rsvp button:hover {
    background-color: #660017; /* Darker Burgundy */
  }
  
  .footer {
    background-color: #800020; /* Burgundy */
    color: white;
    text-align: center;
    padding: 10px;
  }
  
  .footer p {
    margin: 5px 0;
    font-size: 1rem;
  }
  
  /* Keyframes for animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  